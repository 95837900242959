// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useEffect } from "react";
import PropTypes from "prop-types";

class PatientNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_numbers_yearly: props.patient_numbers_yearly,
      errors: props.errors,
      patients: props.initial,
      screening_failures: props.initial,
      year: 2020,
      notification_type: props.notification_type,
    };
  }

  asJsonString = () => {
    return JSON.stringify({
      patient_numbers_yearly: this.state.patient_numbers_yearly,
    });
  };

  removePatientFromList = (index) => {
    this.setState({
      patient_numbers_yearly: this.state.patient_numbers_yearly.filter(
        (_, i) => i !== index
      ),
    });
  };

  updatePatientsUserInputState = (e) => {
    this.setState({ patients: e.target.value });
  };

  updateYearUserInputState = (e) => {
    this.setState({ year: e.target.value });
  };

  updateScreeningFailuresUserInputState = (e) => {
    this.setState({ screening_failures: e.target.value });
  };

  addPatientNumberToList = () => {
    this.setState({
      patient_numbers_yearly: [
        ...this.state.patient_numbers_yearly,
        {
          year: this.state.year,
          patients: this.state.patients,
          screening_failures: this.state.screening_failures,
        },
      ],
      patients: "",
      screening_failures: "",
    });
  };

  addPatientNumberOnButtonClick = (e) => {
    e.preventDefault();
    this.addPatientNumberToList();
  };

  render() {
    const style = {
      width: "300px",
    };
    let classes = ["string", "input", "required"];
    let tableErrorClass = "";
    let currentYear = new Date().getFullYear();
    if (this.state.errors.length !== 0) {
      classes.push("text-red-600");
      tableErrorClass = "text-red-600";
    }

    return (
      <fieldset className="inputs">
        <div className="">
          <p className="mb-4">
            Bitte geben Sie das Jahr und die dazugehörige Anzahl der
            eingeschlossenen Studienpatienten an, sofern Sie diese nicht bereits
            im SAP-System des UKR markiert haben.
          </p>
          <div
            id={this.state.notification_type + "_patient_numbers_yearly_input"}
            className="grid grid-cols-1 items-center"
          >
            <label
              className="flex flex-col mb-4"
              htmlFor={this.state.notification_type + "_patient_numbers_year"}
            >
              Jahr
              <select
                id={this.state.notification_type + "_patient_numbers_year"}
                value={this.state.year}
                onChange={this.updateYearUserInputState}
              >
                {new Array(new Date().getUTCFullYear() - 2020 + 1 + 1)
                  .fill(0)
                  .map((_, i) => i + 2020)
                  .map((x, i) => (
                    <option key={i} value={x}>
                      {x}
                    </option>
                  ))}
              </select>
            </label>
            <label
              className="flex flex-col mb-4"
              htmlFor={this.state.notification_type + "_patient_numbers_yearly"}
            >
              Anzahl der Patienten mit unterschriebener Einwilligungserklärung
              <input
                id={this.state.notification_type + "_patient_numbers_yearly"}
                type="number"
                className=" peer col-span-2"
                onChange={this.updatePatientsUserInputState}
                value={this.state.patients}
              />
            </label>
            <label
              className="flex flex-col mb-4"
              htmlFor={
                this.state.notification_type + "_screening_failures_yearly"
              }
            >
              davon Screening-Failures
              <input
                id={this.state.notification_type + "_screening_failures_yearly"}
                type="number"
                className=" peer col-span-2"
                onChange={this.updateScreeningFailuresUserInputState}
                value={this.state.screening_failures}
              />
            </label>
            <input
              type="hidden"
              name={this.state.notification_type + "[patient_numbers_yearly]"}
              value={this.asJsonString()}
            />

            <div>
              <button
                id="add_patient_numbers_yearly"
                className="btn-primary "
                onClick={this.addPatientNumberOnButtonClick}
              >
                hinzufügen
              </button>
            </div>
          </div>
          <div className="bg-slate-100 px-2 mt-4 mb-6">
            <div className="grid grid-cols-5 items-center py-1 font-semibold">
              <div>Jahr</div>
              <div>Patienten</div>
              <div className="col-span-2">Screening-Failures</div>
              <div></div>
            </div>
            {this.state.patient_numbers_yearly.map((x, i) => (
              <div className="grid grid-cols-5 items-center py-1" key={i}>
                <div>{x.year}</div>
                <div>{x.patients}</div>
                <div>{x.screening_failures}</div>
                <div className="col-span-2 justify-end flex">
                  <a
                    className="btn-danger cursor-pointer"
                    onClick={this.removePatientFromList.bind(this, i)}
                  >
                    entfernen
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </fieldset>
    );
  }
}

PatientNumbers.defaultProps = {
  patient_numbers_yearly: [],
  initial: "",
  year: new Date().getFullYear,
};

PatientNumbers.propTypes = {
  patient_numbers_yearly: PropTypes.array,
  initial: PropTypes.string,
};

export default PatientNumbers;
