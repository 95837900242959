/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import ResponsibleInput from './responsible_input'
import MolecularMarkerInput from "./molecular_marker_input"
import PatientNumberInput from "./patient_number_yearly_input";
import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({ ResponsibleInput })
WebpackerReact.setup({ MolecularMarkerInput });
WebpackerReact.setup({ PatientNumberInput });
