// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

class Markers extends React.Component {
  constructor(props) {
    super(props);
    this.hideDropdown = this.hideDropdown
    this.state = {
      markers: props.markers,
      marker_option_list: props.marker_option_list,
      suggestions: props.marker_option_list.map((el) => {
        return el.name;
      }),
      errors: props.errors,
      current: props.initial,
      dropdownVisible: false,
      notification_type: props.notification_type,
    };
  }

  componentDidMount() {
    document.addEventListener('click', function(e) {
      if (e.target.id != `${this.state.notification_type}_molecular_markers`)
        this.hideDropdown();
    }.bind(this))
  }

  asJsonString = () => {
    return JSON.stringify({ markers: this.state.markers });
  };

  removeMarkerFromList = (index) => {
    this.setState({
      markers: this.state.markers.filter((_, i) => i !== index),
    });
  };

  updateCurrentUserInputState = (e) => {
    this.setState({ current: e.target.value });
    this.filterSuggestions(e.target.value);
  };

  filterSuggestions(userInput) {
    let filteredSuggestions = this.state.marker_option_list.map((el) => {return el.name}).filter((marker) => {
      return marker.toLowerCase().includes(userInput.toLowerCase());
    });
    this.setState({ suggestions: filteredSuggestions });
  }

  addMarkerToList = (name) => {
    this.setState({
      markers: [...this.state.markers, name],
      current: "",
    });
  };

  addMarkerOnEnterKeypress = (e) => {
    let name = e.target.value;
    if (e.key === "Enter") {
      e.preventDefault();
      this.addMarkerToList(name);
    }
  };

  addMarkerOnButtonClick = (e) => {
    e.preventDefault();
    this.addMarkerToList(this.state.current);
  };

  updateCurrentUserInputWithSuggestion = (e) => {
    e.preventDefault();
    this.setState({ current: e.currentTarget.innerText });
  };

  showDropdown = (e) => {
    this.filterSuggestions(e.target.value);
    this.setState({ dropdownVisible: true });
  }
  hideDropdown() {
    this.setState({ dropdownVisible: false });
  }

  render() {
    const style = {
      width: "300px",
    };
    let classes = ["string", "input", "required"];
    let tableErrorClass = "";
    if (this.state.errors.length != 0) {
      classes.push("text-red-600");
      tableErrorClass = "text-red-600";
    }

    return (
      <fieldset className="inputs responsibleInput">
        <div className="">
          <p>Bitte geben Sie alle zutreffenden Marker an</p>
          <div
            id={this.state.notification_type + "_molecular_markers_input"}
            className="grid grid-cols-3 items-center"
          >
            <input
              id={this.state.notification_type + "_molecular_markers"}
              type="text"
              className=" peer col-span-2"
              onKeyPress={this.addMarkerOnEnterKeypress}
              onChange={this.updateCurrentUserInputState}
              onClick={this.showDropdown}
              value={this.state.current}
            />
            <input
              type="hidden"
              name={this.state.notification_type + "[molecular_markers]"}
              value={this.asJsonString()}
            />

            <button
              id="add_molecular_marker"
              className="btn-primary ml-4"
              onClick={this.addMarkerOnButtonClick}
            >
              hinzufügen
            </button>
            <ul
              id="markerList"
              className={`${
                this.state.dropdownVisible ? "block " : "hidden "
              } bg-gray-50 col-span-2 mt-3 overflow-y-auto max-h-64`}
            >
              {this.state.suggestions.map((option, i) => (
                <li
                  key={i}
                  onClick={this.updateCurrentUserInputWithSuggestion}
                  className="hover:bg-blue-100 cursor-pointer p-2"
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-slate-100 px-2 mt-8">
            <table className={tableErrorClass + "w-full "}>
              <tbody>
                {this.state.markers.map((x, i) => (
                  <tr key={i}>
                    <td className="py-3">{x}</td>
                    <td className="py-3"></td>
                    <td className="py-3">
                      <a
                        className="ml-4 btn-danger cursor-pointer"
                        onClick={this.removeMarkerFromList.bind(this, i)}
                      >
                        entfernen
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </fieldset>
    );
  }
}

Markers.defaultProps = {
  markers: [],
  initial: ''
};

Markers.propTypes = {
  markers: PropTypes.array,
  initial: PropTypes.string
};

export default Markers
