// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import PropTypes from 'prop-types'

class Responsible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: props.users,
      errors: props.errors,
      current: props.initial,
      invalidAdress: false,
      notification_type: props.notification_type
    }
  }

  asString = () => {
    return JSON.stringify({users: this.state.users})
  };

  remove = (index) => {
    this.setState({users: this.state.users.filter((_, i) => i !== index)});
  };

  makeResponsible = (index) => {
    this.setState({users: this.state.users.map((u, i) => {
      return { email: u.email, responsible: i === index }
    })});
  };

  handleChange = (e) => {
    this.setState({current: e.target.value});
  };

  addPerson = (name) => {
    if (name.match(/^[a-z0-9+_-][a-z0-9+._-]*$/i)) {
      this.setState({invalidAdress: false, users: [...this.state.users, {email: name + '@ukr.de', responsible: false}], current: ''});
    } else {
      this.setState({errors: this.state.errors.push('invalid input') ,invalidAdress: true})
    }
  };

  handleKeyPress = (e) => {
    let name = e.target.value;
    if (e.key === 'Enter') {
      e.preventDefault();
      this.addPerson(name);
    }
  };

  handleOnClick = (e) => {
    e.preventDefault();
    this.addPerson(this.state.current);
  };


  render() {
    const style = {
      width: '300px'
    };
    let classes = ['string', 'input', 'required', 'stringish'];
    let tableErrorClass = '';
    if(this.state.errors.length != 0 && this.state.invalidAdress ) {
      classes.push('text-red-600');
    }
    if (this.state.errors.length != 0 && !this.state.invalidAdress) {
      tableErrorClass = "text-red-600";
    }

    return (
      <fieldset className="inputs responsibleInput">
        <ol>
          <li>
            <p>
              Bitte geben Sie alle zuständigen Personen{" "}
              <strong>mit SAP-Zugang</strong> für diese Studie an. Dabei ist
              eine Person als primärer Ansprechpartner für z.B. Leitstellen oder
              die Notaufnahme zu kennzeichnen. Alle genannten Personen bekommen
              über das SAP-System eine automatische Benachrichtigung
              (SAE-Warnmeldung), sobald ein Studienpatient einer AMG- oder
              MPG-Studie stationär aufgenommen wird.
            </p>
            <p className="mt-2">
              <strong>Achtung:</strong> <br /> Kliniken ohne SAP-Zugang und ggf.
              auch ohne @ukr.de Adresse tragen bitte einfach Vorname.Nachname
              des Hauptprüfers ein und machen diesen zum primären
              Ansprechpartner.
            </p>
          </li>
          <div
            id={this.state.notification_type + "_responsible_input"}
            className="grid grid-cols-4 items-center"
          >
            <input
              id={this.state.notification_type + "_responsible"}
              type="text"
              className="col-span-2"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              value={this.state.current}
            />
            <p className="">&nbsp;@ukr.de</p>
            <input
              type="hidden"
              name={this.state.notification_type + "[responsible]"}
              value={this.asString()}
            />

            <button id="addResponsible" className="btn-primary" onClick={this.handleOnClick}>
              hinzufügen
            </button>

            {this.state.errors.length != 0 && !this.state.invalidAdress && (
              <p className="text-red-600 mt-2">
                Hauptverantwortlicher muss gewählt werden
              </p>
            )}
            {this.state.invalidAdress && (
              <p className="text-red-600 mt-2">keine gültige Email-Adresse</p>
            )}
          </div>
          <div className="bg-slate-100 px-2 pt-4 mt-8">
            <table className={tableErrorClass + "w-full "}>
              <tbody>
                {this.state.users.map((x, i) => (
                  <tr key={i}>
                    <td className="pb-6">{x.email}</td>
                    <td className="pb-6">
                      {x.responsible ? (
                        <span className="ml-4">(hauptverantwortlich)</span>
                      ) : (
                        <a
                          className="ml-4 btn-primary cursor-pointer"
                          id="mark"
                          onClick={this.makeResponsible.bind(this, i)}
                        >
                          als hauptverantwortlich markieren
                        </a>
                      )}
                    </td>
                    <td className="pb-6">
                      <a
                        className="ml-4 btn-danger cursor-pointer"
                        onClick={this.remove.bind(this, i)}
                      >
                        entfernen
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ol>
      </fieldset>
    );
  }
}

Responsible.defaultProps = {
  users: [],
  initial: ''
};

Responsible.propTypes = {
  users: PropTypes.array,
  initial: PropTypes.string
};

export default Responsible
